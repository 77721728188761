import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import './counter.scss';

const Counter = ({ begin = 0, end, duration = 2, msg }) => {
	const [start, setStart] = useState(begin);

	const onEnd = () => {
		setStart(end);
	};
	return (
		<div className='counter'>
			<VisibilitySensor partialVisibility offset={{ bottom: 20 }}>
				{({ isVisible }) => (
					<div>
						{isVisible ? (
							<CountUp
								end={end}
								start={start}
								duration={duration}
								redraw={false}
								onEnd={onEnd}
							/>
						) : (
							<span>{start}</span>
						)}
						<hr />
						<p className='counter-msg'>{msg}</p>
					</div>
				)}
			</VisibilitySensor>
		</div>
	);
};
export default Counter;
