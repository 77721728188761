import * as React from 'react';
import { useEffect, useState } from 'react';
import Layout from '../components/dfa-theme/layout';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link as ScrollLink, Element } from 'react-scroll';
import ContentBlock from '../components/content-block';
import CookieBanner from '../components/cookie-banner';
import Counter from '../components/counter';
import Quote from '../components/quote';
import hero_bg from '../images/0.0_MonickerHeartbeat.svg';
import groupShot from '../images/3.0_Culture_HumanInspired.webp';
import AnniversaryBadge from '../images/DFA_10Year_Primary.png';
import Awards from '../components/awards';
import BadgeComponent from '../components/badge-component';
import useBadge from '../hooks/useBadge';

import { CONSTANTS } from '../utils/constants';

import '../scss/pages/index.scss';

const IndexPage = () => {
	const showBadge = useBadge(650);

	const metaTags = {
		name: 'Home',
		description: 'Deerfield Homepage',
		keywords: 'Deerfield, Homepage',
	};

	let ticking = false;
	let aboveTop = false;

	const checkTripwire = () => {
		const tripwire = document.querySelector('.tripwire-container');
		const heroBg = document.querySelector('.hero-bg');
		if (tripwire) {
			const tripwirePosition = tripwire.getBoundingClientRect();
			if (tripwirePosition.top <= 0 && aboveTop === false) {
				heroBg.classList.add('absolute');
				aboveTop = true;
			}

			if (tripwirePosition.top >= 0 && aboveTop === true) {
				heroBg.classList.remove('absolute');
				aboveTop = false;
			}
		}
	};

	useEffect(() => {
		document.addEventListener('scroll', (e) => {
			if (!ticking) {
				window.requestAnimationFrame(() => {
					checkTripwire();
					ticking = false;
				});

				ticking = true;
			}
		});
	}, []);


	return (
		<Layout meta={metaTags}>
			<CookieBanner />
			<div className='super-container block'>
				<div className='hero-container'>
					<ContentBlock className='hero'>
						<Grid>
							<Row>
								<Col xs={1} md={2}></Col>
								<Col xs={10} md={8} className='hero-content title-block'>
									<ScrollLink
										activeClass='active'
										to='philoshy'
										spy={false}
										smooth={true}
										duration={500}
										offset={-75}
										rel='nofollow noopener'
										href='#'
										aria-label='jump to philosophy section.'
									>
										<div className='hero-arrow bounce'></div>
									</ScrollLink>
									<object
										type='image/svg+xml'
										data={hero_bg}
										className='hero-bg'
										aria-label='logo animation'
									>
										logo-animation
									</object>
									<img
										src={AnniversaryBadge}
										alt='Anniversary Badge'
										className='anniversary-img'
									/>
									<div className='hero-copy'>
										<p className='header-orange add40' name='firstLink'>
											welcome to deerfield
										</p>
										<h1 className='plus5'>More than an agency.</h1>

									</div>
								</Col>
								<Col xs={1} md={2}></Col>
							</Row>
						</Grid>
					</ContentBlock>
				</div>
				<div className='philosophy-container'>
					<ContentBlock className='philosophy'>
						<Grid>
							<Row>
								<Col xs={1} md={2}></Col>
								<Col xs={10} md={8}>
									<Element name='philoshy' className='element'></Element>
									<div className='philosophy-content'>
										<p className='header-orange '>philosophy</p>
										<h1 className='skinny'>
											Meet your
											<br />{' '}
											<span className='aob'>
												Agency of Brand<sup>®</sup>.
											</span>
											<br /> No bias. No&nbsp;egos. <br />
										</h1>
										<p className='text-center body-copy'>
											As an extension of your team, we're pushing the limits of
											what an agency can do. This starts with giving you more.
											More listening to genuinely understand your challenges and
											needs. More action in leveraging our experts to get you in
											the room with the right team at the right time. Our more
											allows you to maximize your marketing efforts &ndash;
											every time.
										</p>

										<div className='counters'>
											<Counter end={24} msg='therapeutic categories'></Counter>
											<Counter end={116} msg='healthcare brands'></Counter>
											<Counter end={35} msg='product launches'></Counter>
										</div>
										<a href='/leadership' className='dfa-button-orange'>
											meet our experts
										</a>
									</div>

								</Col>
								<Col xs={1} md={2}></Col>
							</Row>
						</Grid>
					</ContentBlock>
				</div>
				<div className='solutions-container'>
					<ContentBlock className='solutions-home'>
						<Grid>
							<Row>
								<Col xs={1} md={2}></Col>
								<Col xs={10} md={8}>
									<div className='solutions-content flex-center'>
										<p className='header-orange'>solutions</p>
										<h1>
											Uniquely crafted solutions by strategic problem-solvers
										</h1>
										<a
											className='dfa-button-orange'
											href='/solutions'
											aria-label="learn about Deerfield's solutions."
										>
											find a solution{' '}
										</a>
									</div>

								</Col>
								<Col xs={1} md={2}></Col>
							</Row>
						</Grid>
					</ContentBlock>
					<ContentBlock
						outerBgColor={CONSTANTS.BROWN}
						className='tripwire-container'
					>
						{' '}
						&nbsp;
					</ContentBlock>
				</div>
			</div>
			<div
				className='philosophy-container'
				style={{ backgroundColor: 'white' }}
			>

				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
					<Grid>
						<Row>
							<Col xs={1} md={1}></Col>
							<Col xs={10} md={10}>
								<Quote
									msg={`Deerfield is a true partner that is involved in every aspect of our brands, and I look forward to continuing to do great things with them moving forward.”`}
									from={`Marketing Director`}
								/>
							</Col>
							<Col xs={1} md={1}></Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='careers'>
					<Grid>
						<Row>
							<Col xs={12} md={12} className='careers-content'>
								<img
									src={groupShot}
									alt='The Deerfeild team in a group photo.'
									className='careers-img'
								/>
							</Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='careers block'>
					<Grid>
						<Row>
							<Col xs={1} md={1}></Col>
							<Col xs={10} md={10} className='careers-content'>
								<p className='header-orange dark'>careers</p>
								<h2 className='blue skinny text-center  homeCareer'>
									We saved you a seat
								</h2>
								<p className='dark careers-copy'>
									Bringing qualified agency professionals to the table is our
									specialty.
									<br /> Are you who we're looking for?
								</p>

								<a href='/careers' className='dfa-button-orange'>
									claim your spot
								</a>
							</Col>
							<Col xs={1} md={1}></Col>
						</Row>
						<Row>
							<Col xs={1} md={1}></Col>
							<Col xs={10} md={10} className=''>
								<Awards className='careers-awards' />
							</Col>
							<Col xs={1} md={1}></Col>
						</Row>
					</Grid>
				</ContentBlock>
			</div>
			<BadgeComponent isVisible={showBadge} />
		</Layout>
	);
};

export default IndexPage;
