import React, { useState, useEffect } from 'react';
import closeIcon from '../../images/btn_x.png';
import { useCookies } from 'react-cookie'; // Import the hook
import { Link } from "gatsby";
import './cookie-banner.scss';  // assuming you'll have a separate CSS file for styles

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [cookies, setCookie] = useCookies(['isBannerShown']); // Initialize the hook

  useEffect(() => {
    // Check if the cookie exists
    const isBannerShown = cookies.isBannerShown;

    // If the cookie doesn't exist (new user or expired), show the banner
    if (!isBannerShown) {
      setIsVisible(true);
    }
  }, [cookies]);

  const handleBannerClose = () => {
    // Hide the banner
    setIsVisible(false);

    // Set a cookie for a few weeks to remember the user has seen the banner
    const weeksDuration = 1;  // Change this to set the duration
    setCookie('isBannerShown', 'true', { maxAge: 7 * 24 * 60 * 60 * weeksDuration });  // Cookie will expire after (7 days x weeksDuration)
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookie-banner">
      <div className="content-wrapper">
        <h2>Cookie Policy</h2>
        <div className="paragraph-wrapper">
          <p>
            We use cookies to provide you with the best possible experience. By continuing to use our site, you agree to our use of cookies.
            <br />
            To learn more, visit our <Link to="/privacy" onClick={handleBannerClose}>privacy policy</Link>.
          </p>
          <button onClick={handleBannerClose} className="close-button">
            <img src={closeIcon} alt="Close banner" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
